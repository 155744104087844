.segment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3,
  h5 {
    margin: 0;
  }

  h5 {
    color: #00000099;
    margin-top: 0.25rem;
    font-weight: 400;
  }

  &__icon {
    margin-left: 10px !important;
  }

  &__title {
    display: flex;
    align-items: center;
  }
}
