.step-2-language-tabs {
  &__tab-title {
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__tab {
    margin-bottom: 32px;
  }

  &__first-text {
    margin-top: 8px !important;
  }
}
