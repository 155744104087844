.live-preview {
  position: sticky !important;
  top: 0;
  z-index: 10;

  &__bar {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__text {
    flex: 1;
  }

  &__close-btn {
    cursor: pointer;
    font-family: 'sans-serif';
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    padding: 10px;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    z-index: 1;
  }
}