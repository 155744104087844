.popup-question-icon {
  display: flex;
  align-items: center;

  &__icon {
    margin-bottom: 1.5rem !important;
    margin-left: 1rem !important;
    cursor: help;
  }
}
