.other-apps {
  color: #7f7f7f;
  text-align: center;

  p {
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    & > a {
      margin: auto;
    }
  }
}
