.brand-header {
  margin-bottom: 1rem;

  &__segment {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-container {
    display: flex;
    align-items: center;
  }

  &__img {
    margin-right: 1rem;
    width: 50px;
  }

  &__title {
    font-size: 1.4rem;
  }

  &__email {
    margin-left: 32px;
  }

  &__greeting {
    margin-left: 32px;
  }
}
