@import "~normalize.css/normalize.css";

html {
  font-size: 16px;
}

body {
  background: #f6f6f6;
  min-height: 600px !important;
  overflow-y: scroll;
}

.ui.form .field {
  margin-bottom: 1.5rem;
}

.ui.form .fields {
  margin-bottom: 1.5em;
}

.ui.form .field > label {
  font-weight: 400 !important;
}

.ui.vertical.menu {
  width: 100%;
}

.chrome-picker input {
  padding: 0 !important;
}
