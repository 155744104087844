.bars-pdd {
  &__content {
    min-height: 10rem;
  }
  &__placeholder {
    font-size: 20px !important;

    & > div {
      margin-top: 10px !important;
    }
  }
}
