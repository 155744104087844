.step-2-language-tabs {
  &__form-group {
    align-items: flex-end;
  }

  &__popup {
    height: 32px;
    padding: 0 1rem;
    min-width: 50px;
    text-align: center;
    flex: 1;

    &:hover.help {
      cursor: help;
    }
  }

  &__tab {
    margin-bottom: 32px;
  }

  &__sub {
    bottom: 1.75em;
  }
}
