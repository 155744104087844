.form-color-picker {
  &__outer-box {
    position: relative;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #e4e4e4;
    width: 50px;
  }

  &__inner-box {
    height: 15px;
    border-radius: 2px;
  }

  &__position {
    position: absolute;
    bottom: 2rem;
    left: 0;
    z-index: 11;
  }

  &__dimmer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
}
